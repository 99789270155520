var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"_dev@eda8211f2"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { ExtraErrorData, HttpClient } from '@sentry/integrations';

Sentry.init({
    debug: false,
    dsn: 'https://37330437a5e74ed89874a7a2a9174382@o4504730350059520.ingest.sentry.io/4504730351108096',
    enableTracing: true,
    tracesSampleRate: 1,
    sampleRate: 1,
    // replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    release: process.env.NEXT_PUBLIC_APP_VERSION,
    environment: 'production',
    attachStacktrace: false,
    integrations: [
        new Sentry.Replay({
            // All text are blocked by default
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
            networkDetailAllowUrls: [
                'https://api.danim.com/upload/me',
                'https://api.danim.com/read',
                'https://api.danim.com/write',
                'https://api.danim.com/tokens/generate',
                'https://api.danim.com/tokens/refresh',
                'https://api.danim.com/third/soundstripe/v1/songs',
                'https://app.orpistory.com/api/upload/me',
                'https://app.orpistory.com/api/read',
                'https://app.orpistory.com/api/write',
                'https://app.orpistory.com/api/tokens/generate',
                'https://app.orpistory.com/api/tokens/refresh',
                'https://app.orpistory.com/api/third/soundstripe/v1/songs'
            ],
            networkRequestHeaders: ['X-Xsrf', 'Cookie'],
            networkCaptureBodies: true
            // beforeAddRecordingEvent: event => {
            //     if (
            //         event.data.tag === 'performanceSpan' &&
            //         (event.data.payload.op === 'resource.fetch' || event.data.payload.op === 'resource.xhr') &&
            //         event.data.payload.data.response.body?.success === false
            //     )
            //         return event;
            //
            //     if (
            //         event.data.tag === 'performanceSpan' &&
            //         (event.data.payload.op === 'resource.fetch' || event.data.payload.op === 'resource.xhr') &&
            //         event.data.payload.data.statusCode !== 500
            //     ) {
            //         return null;
            //     }
            //
            //     return event;
            // }
        }),
        new ExtraErrorData({ depth: 10 }),
        new HttpClient()
    ],
    ignoreErrors: [
        /routeChange aborted/i,
        /loopScroll/i,
        /play/i,
        /The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission./i,
        /Failed to load Stripe.js/i,
        /gc.kes.v2.scr.kaspersky-labs.com/i,
        /cdnmc.global-cache.online/i,
        /canceled/i,
        /The operation was aborted./i,
        /logMutedMessage/i,
        /Invariant/i,
        /The fetching process for the media resource was aborted by the user agent at the user's request./i,
        /Failed to execute 'send' on 'XMLHttpRequest'(.*)/i,
        /undefined is not an object (evaluating 'e.substring')/i,
        /null is not an object (.*)/i,
        /Blocked 'connect' from 'player.vimeo.com'/i,
        /AbortError/i,
        /NotSupportedError/i,
        /UnknownError/i,
        /Unexpected error/i,
        /HTTP Client Error with status code: 502/i,
        /The operation timed out./i,
        /Cannot read properties of null(.*)/i,
        /Cancel rendering route§/i,
        /Invalid array length/i,
        /Attempt to use history.pushState(.*)/i
    ]
});
